const walletConfig = [
    {   network:['eth','bsc'],
        parent:'eth',
        name:'MetaMask',
        image:'https://cdn.iconscout.com/icon/free/png-512/metamask-2728406-2261817.png',
        token:'injected'
    },
    {   network:['eth','bsc'],
        name:'WalletConnect',
        image:'https://walletconnect.org/walletconnect-logo.svg',
        token:'walletconnect' 
    },
    {   network:['eth','bsc'],
        name:'Portis',
        image:'https://avatars.githubusercontent.com/u/37375814?s=280&v=4',
        token:'portis'
    },

    {   network:['eth'],
        name:'Torus',
        image:'https://pbs.twimg.com/profile_images/1382598219750805505/TCP-6Zw0_400x400.png',
        token:'torus'
    },
    {   network:['eth'],
        name:'authereum',
        image:'https://cdn.iconscout.com/icon/free/png-512/metamask-2728406-2261817.png',
        token:'Authereum'
    },
    {   network:['eth','bsc'],
        name:'Trust Wallet',
        image:'https://registry.walletconnect.org/logo/lg/4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0.jpeg',
        token:'walletconnect'
    },
    {   network:['eth','bsc'],
        name:'Huobi Wallet',
        image:'https://registry.walletconnect.org/logo/lg/bae74827272509a6d63ea25514d9c68ad235c14e45e183518c7ded4572a1b0c4.jpeg',
        token:'walletconnect'
    },
    {   network:['eth','bsc'],
        name:'Binance',
        image:'https://user-images.githubusercontent.com/12424618/54043975-b6cdb800-4182-11e9-83bd-0cd2eb757c6e.png',
        token:'bsc'
    }

];

export {walletConfig};