import React from 'react'

function AboutIntro({history}) {
    return (
        <div className="">
                    <div className="col-12 col-md-8 m-auto text-center demo-content">
                        
                        <div className="about__title__height">
                            <div className="intro__title">
                                ABOUT US
                            </div>
                            <div className="about__sub__title2">
                            Creating a stable platform for the global financial system
                        </div>
                        </div>
                    </div>
                    <div className="container position__relative mt-5 pt-5 pb-5">
                        <div className="flex-1 text-right circular">
                            {/* <img src="/assets/circular.png" /> */}
                        </div>
                    </div>
                </div>
    )
}

export default AboutIntro
