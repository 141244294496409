import React from 'react'

function Social() {
    return (
        <section className="">
                <div className=" pt-5">
                    <div className="">
                        <div className="row pb-5 ">
                            <div className="col-12 col-md-12 m-auto">
                                <p className="built__on">
                                    <p className="digital__cash">
                                        LET'S GET EVOLVED
                                    </p>
                                    JOIN OUR COMMUNITY
                                </p>
                            </div>
                            
                        </div>
                       <div className="col-12 col-md-9 m-auto">
                       <div className="row">
                                <div className="col-6 col-md-3 text-center mt-5">
                                    <div className="btn__card d-flex p-2 text-center">
                                        <div className="m-auto">
                                            <img alt="#" src="/assets/tele.png" className="img-fluid" />
                                        </div>
                                        <div className="flex-1 ml-2 mt-auto mb-auto">
                                            TELEGRAM
                                            <p className="button__label">Let's Discuss</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 text-center mt-5">
                                    <div className="btn__card d-flex p-2 text-center">
                                        <div className="m-auto">
                                            <img alt="#" src="/assets/discord1.png" className="img-fluid" />
                                        </div>
                                        <div className="flex-1 ml-2 mt-auto mb-auto">
                                            DISCORD
                                            <p className="button__label">Let's Discuss</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-6 col-md-3 text-center mt-5">
                                    <div className="btn__card d-flex p-2 text-center">
                                        <div className="m-auto">
                                            <img alt="#" src="/assets/github1.png" className="img-fluid" />
                                        </div>
                                        <div className="flex-1 ml-2 mt-auto mb-auto">
                                            GITHUB
                                            <p className="button__label">Let's Discuss</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-6 col-md-3 text-center mt-5">
                                    <div className="btn__card d-flex p-2 text-center">
                                        <div className="m-auto">
                                            <img alt="#" src="/assets/insta1.png" className="img-fluid" />
                                        </div>
                                        <div className="flex-1 ml-2 mt-auto mb-auto">
                                            INSTAGRAM
                                            <p className="button__label">Let's Discuss</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-6 col-md-3 text-center mt-5">
                                    <div className="btn__card d-flex p-2 text-center">
                                        <div className="m-auto">
                                            <img alt="#" src="/assets/tweet1.png" className="img-fluid" />
                                        </div>
                                        <div className="flex-1 ml-2 mt-auto mb-auto">
                                            TWITTER
                                            <p className="button__label">Let's Discuss</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-6 col-md-3 text-center mt-5">
                                    <div className="btn__card d-flex p-2 text-center">
                                        <div className="m-auto">
                                            <img alt="#" src="/assets/fb1.png" className="img-fluid" />
                                        </div>
                                        <div className="flex-1 ml-2 mt-auto mb-auto">
                                            FACEBOOK
                                            <p className="button__label">Let's Discuss</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-6 col-md-3 text-center mt-5">
                                    <div className="btn__card d-flex p-2 text-center">
                                        <div className="m-auto">
                                            <img alt="#" src="/assets/linkedin1.png" className="img-fluid" />
                                        </div>
                                        <div className="flex-1 ml-2 mt-auto mb-auto">
                                            LINKEDIN
                                            <p className="button__label">Let's Discuss</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 text-center mt-5">
                                    <div className="btn__card d-flex p-2 text-center">
                                        <div className="m-auto">
                                            <img alt="#" src="/assets/medium1.png" className="img-fluid" />
                                        </div>
                                        <div className="flex-1 ml-2 mt-auto mb-auto">
                                            MEDIUM
                                            <p className="button__label">Let's Discuss</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>
            </section>
            
    )
}

export default Social
