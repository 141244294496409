import React from 'react';

const Loader = () =>{
    return(
        <div className={'loader'}>
            <p>Loading...</p>
        </div>
    )
}

export default Loader;